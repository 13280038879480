import React, { useState } from "react";
import cx from "classnames";
import loadable from "@loadable/component";

import CloseIcon from "@svg/close.svg";
import PlayIcon from "@svg/play.svg";

import { ModuleWrapper, useModuleContext } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import PortalModal from "@components/ui/portalModal";

const VideoComponent = loadable(() => import("react-player/lazy"));

const InnerComponent = ({ image, text, layout, videoFile, teaserVideo }) => {
  const { inView } = useModuleContext();
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="px-gutter grid gap-y-10 lg:grid-cols-2 lg:gap-x-16 lg:items-center">
      {videoFile && (
        <div
          className={cx("md:row-start-1 cursor-pointer", {
            "md:col-start-2": layout === "right",
            "md:col-start-1": layout === "left",
          })}
          onClick={() => setOpen(true)}
        >
          <div className="aspect-w-1 aspect-h-1 relative rounded-lg overflow-hidden">
            <div className="w-full h-full absolute top-0 left-0 bg-black bg-opacity-50 z-20 flex items-center justify-center">
              <PlayIcon className="w-12 text-white fill-current" />
            </div>
            <div className="bg-video absolute w-full h-full top-0 left-0 z-0 rounded-lg overflow-hidden">
              {inView && (
                <VideoComponent
                  url={teaserVideo || videoFile}
                  loop
                  playing
                  muted
                  playsinline
                  width="100%"
                  height="auto"
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="md:row-start-1">
        {text && <PortableTextBlock text={text} />}
      </div>
      <PortalModal isShowing={isOpen}>
        <div
          className=" text-red absolute top-4 right-4 cursor-pointer scale-100 hover:scale-110 transform transition-all duration-150 z-20"
          onClick={() => setOpen(false)}
        >
          <CloseIcon className="fill-current w-6" />
        </div>
        <VideoComponent
          url={videoFile}
          controls
          playing
          width="100%"
          height="100%"
          className="fit-video z-10"
        />
      </PortalModal>
    </div>
  );
};

const ImageVideo = ({ config, ...props }) => {
  return (
    <ModuleWrapper {...config}>
      <InnerComponent {...props} />
    </ModuleWrapper>
  );
};

export default ImageVideo;
